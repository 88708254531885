import { reactive, watch } from '@vue/composition-api';

export default function(bind_obj, ctx) {
  const query = reactive({ ...bind_obj, ...ctx.root.$route.query });

  watch(query, function(new_val) {
    ctx.root.$router.push({ query: { ...new_val }});
  }, { immediate: true });

  return { query };
}
