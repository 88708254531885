import axios from 'axios'
const api = axios.create({
  baseURL: process.env.VUE_APP_AI_PRONUN || 'https://stg-ai-pronunciation.x3english.com',
  mode: 'no-cors'
});
const aiEnpointPronun = process.env.VUE_APP_AI_PRONUN || 'https://stg-ai-pronunciation.x3english.com';
const aiEnpointS2t = process.env.MIX_AI_S2T || 'https://ai.x3english.org';
const aiAdminUrl = process.env.VUE_APP_ADMIN_URL || 'https://stg-ai-8078.x3english.com';
const aiUrl = process.env.VUE_APP_AI_URL || 'https://stg-ai-s2t.x3english.com';

export default {
  pronunciation(data) {
		return api.post('/phone/api/x3/pronunciation', data, {
      baseURL: aiEnpointPronun,
    });
	},
  pronunciationZh(data) {
		return api.post('/phone/api/x3/chinese/pronunciation', data, {
      baseURL: aiEnpointPronun,
    });
    // return fetch(`${aiEnpointPronun}/phone/api/x3/chinese/pronunciation`, {
    //   method: 'POST',
    //   headers: {
    //     'Content-Type': 'application/json',
    //     // Add any additional headers here if needed
    //   },
    //   body: JSON.stringify(data),
    // });
	},
  async updatePronunciationWords(data) {
		const response = await api.post('/api/v1/gop/update_word', data, {
			baseURL: aiAdminUrl,
		});
		return response;
	},
	async recognize(data) {
		const response = await api.post('/api/x3/recognize', data, {
			baseURL: aiEnpointS2t,
		}).catch((error) => {
			dispatch('pushErrorNotify', error.message, { root: true });
		});
		if (!response.data.success) {
			dispatch('pushErrorNotify', response.data.msg, { root: true });
		};
		return response.data.result;
	},
	async updateSpeechToTextWords(data) {
		const response = await api.post('/api/v1/asr/update_text', data, {
			baseURL: aiAdminUrl,
		});
		return response;
	},
	async recognizeNew(data) {
		const response = await api.post('/api/v2/x3/recognize', data, {
			baseURL: aiUrl,
		});
		if (!response.data.success) {
			dispatch('pushErrorNotify', response.data.msg, { root: true });
		};
		return response.data.result;
	},

}
